<template>
    <base-card>
        <!-- <v-card-title
            v-if="title"
            class="title-post"
        >
            <span> {{ title }}</span>
        </v-card-title> -->

        <!-- Edited users-->
        <v-card-text>
            <gameComponent
                :gameId="gameId"
                :userId="userId"
            />

            <!--            <nextPostBtn
          :url-next-post="nextPost"
          @changed:post="updateIndexShowPost"
      />-->
        </v-card-text>
    </base-card>

    <!--    <div> {{ post && post.attributes && post.attributes.body }} </div>-->
</template>

<script>
import { mapGetters } from 'vuex';
import gameComponent from '@/components/games/gameComponnent';
// import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'EvaluateUser',
    components: { gameComponent /*, nextPostBtn */ },
    data: () => ({
        userId: null,
        gameId: null
    }),
    computed: {
        ...mapGetters(['isAdmin']),
        ...mapGetters('permissions', ['canManageGames'])
    },
    created() {
        const { user: userId, game: gameId } = this.$route.params;
        if (isNaN(userId) || isNaN(gameId)) {
            this.$router.push('/');

            return;
        }
        this.userId = +userId;
        this.gameId = +gameId;
    },
    methods: {}
};
</script>

<style lang="css" scoped></style>
